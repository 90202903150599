/*!
 * Draggable 3.12.5
 * https://gsap.com
 *
 * @license Copyright 2008-2024, GreenSock. All rights reserved.
 * Subject to the terms at https://gsap.com/standard-license or for
 * Club GSAP members, the agreement issued with that membership.
 * @author: Jack Doyle, jack@greensock.com
 */
/* eslint-disable */

import { getGlobalMatrix, Matrix2D } from "./utils/matrix.js";

let gsap, _win, _doc, _docElement, _body, _tempDiv, _placeholderDiv, _coreInitted, _checkPrefix, _toArray, _supportsPassive, _isTouchDevice, _touchEventLookup, _isMultiTouching, _isAndroid, InertiaPlugin, _defaultCursor, _supportsPointer, _context, _getStyleSaver,
	_dragCount = 0,
	_windowExists = () => typeof(window) !== "undefined",
	_getGSAP = () => gsap || (_windowExists() && (gsap = window.gsap) && gsap.registerPlugin && gsap),
	_isFunction = value => typeof(value) === "function",
	_isObject = value => typeof(value) === "object",
	_isUndefined = value => typeof(value) === "undefined",
	_emptyFunc = () => false,
	_transformProp = "transform",
	_transformOriginProp = "transformOrigin",
	_round = value => Math.round(value * 10000) / 10000,
	_isArray = Array.isArray,
	_createElement = (type, ns) => {
		let e = _doc.createElementNS ? _doc.createElementNS((ns || "http://www.w3.org/1999/xhtml").replace(/^https/, "http"), type) : _doc.createElement(type); //some servers swap in https for http in the namespace which can break things, making "style" inaccessible.
		return e.style ? e : _doc.createElement(type); //some environments won't allow access to the element's style when created with a namespace in which case we default to the standard createElement() to work around the issue. Also note that when GSAP is embedded directly inside an SVG file, createElement() won't allow access to the style object in Firefox (see https://gsap.com/forums/topic/20215-problem-using-tweenmax-in-standalone-self-containing-svg-file-err-cannot-set-property-csstext-of-undefined/).
	},
	_RAD2DEG = 180 / Math.PI,
	_bigNum = 1e20,
	_identityMatrix = new Matrix2D(),
	_getTime = Date.now || (() => new Date().getTime()),
	_renderQueue = [],
	_lookup = {}, //when a Draggable is created, the target gets a unique _gsDragID property that allows gets associated with the Draggable instance for quick lookups in Draggable.get(). This avoids circular references that could cause gc problems.
	_lookupCount = 0,
	_clickableTagExp = /^(?:a|input|textarea|button|select)$/i,
	_lastDragTime = 0,
	_temp1 = {}, // a simple object we reuse and populate (usually x/y properties) to conserve memory and improve performance.
	_windowProxy = {}, //memory/performance optimization - we reuse this object during autoScroll to store window-related bounds/offsets.
	_copy = (obj, factor) => {
		let copy = {}, p;
		for (p in obj) {
			copy[p] = factor ? obj[p] * factor : obj[p];
		}
		return copy;
	},
	_extend = (obj, defaults) => {
		for (let p in defaults) {
			if (!(p in obj)) {
				obj[p] = defaults[p];
			}
		}
		return obj;
	},
	_setTouchActionForAllDescendants = (elements, value) => {
		let i = elements.length,
			children;
		while (i--) {
			value ? (elements[i].style.touchAction = value) : elements[i].style.removeProperty("touch-action");
			children = elements[i].children;
			children && children.length && _setTouchActionForAllDescendants(children, value);
		}
	},
	_renderQueueTick = () => _renderQueue.forEach(func => func()),
	_addToRenderQueue = func => {
		_renderQueue.push(func);
		if (_renderQueue.length === 1) {
			gsap.ticker.add(_renderQueueTick);
		}
	},
	_renderQueueTimeout = () => !_renderQueue.length && gsap.ticker.remove(_renderQueueTick),
	_removeFromRenderQueue = func => {
		let i = _renderQueue.length;
		while (i--) {
			if (_renderQueue[i] === func) {
				_renderQueue.splice(i, 1);
			}
		}
		gsap.to(_renderQueueTimeout, {overwrite:true, delay:15, duration:0, onComplete:_renderQueueTimeout, data:"_draggable"}); //remove the "tick" listener only after the render queue is empty for 15 seconds (to improve performance). Adding/removing it constantly for every click/touch wouldn't deliver optimal speed, and we also don't want the ticker to keep calling the render method when things are idle for long periods of time (we want to improve battery life on mobile devices).
	},
	_setDefaults = (obj, defaults) => {
		for (let p in defaults) {
			if (!(p in obj)) {
				obj[p] = defaults[p];
			}
		}
		return obj;
	},
	_addListener = (element, type, func, capture) => {
		if (element.addEventListener) {
			let touchType = _touchEventLookup[type];
			capture = capture || (_supportsPassive ? {passive: false} : null);
			element.addEventListener(touchType || type, func, capture);
			(touchType && type !== touchType) && element.addEventListener(type, func, capture);//some browsers actually support both, so must we. But pointer events cover all.
		}
	},
	_removeListener = (element, type, func, capture) => {
		if (element.removeEventListener) {
			let touchType = _touchEventLookup[type];
			element.removeEventListener(touchType || type, func, capture);
			(touchType && type !== touchType) && element.removeEventListener(type, func, capture);
		}
	},
	_preventDefault = event => {
		event.preventDefault && event.preventDefault();
		event.preventManipulation && event.preventManipulation();  //for some Microsoft browsers
	},
	_hasTouchID = (list, ID) => {
		let i = list.length;
		while (i--) {
			if (list[i].identifier === ID) {
				return true;
			}
		}
	},
	_onMultiTouchDocumentEnd = event => {
		_isMultiTouching = (event.touches && _dragCount < event.touches.length);
		_removeListener(event.target, "touchend", _onMultiTouchDocumentEnd);
	},

	_onMultiTouchDocument = event => {
		_isMultiTouching = (event.touches && _dragCount < event.touches.length);
		_addListener(event.target, "touchend", _onMultiTouchDocumentEnd);
	},
	_getDocScrollTop = doc => _win.pageYOffset  || doc.scrollTop || doc.documentElement.scrollTop || doc.body.scrollTop || 0,
	_getDocScrollLeft = doc => _win.pageXOffset || doc.scrollLeft || doc.documentElement.scrollLeft || doc.body.scrollLeft || 0,
	_addScrollListener = (e, callback) => {
		_addListener(e, "scroll", callback);
		if (!_isRoot(e.parentNode)) {
			_addScrollListener(e.parentNode, callback);
		}
	},
	_removeScrollListener = (e, callback) => {
		_removeListener(e, "scroll", callback);
		if (!_isRoot(e.parentNode)) {
			_removeScrollListener(e.parentNode, callback);
		}
	},
	_isRoot = e => !!(!e || e === _docElement || e.nodeType === 9 || e === _doc.body || e === _win || !e.nodeType || !e.parentNode),
	_getMaxScroll = (element, axis) => {
		let dim = (axis === "x") ? "Width" : "Height",
			scroll = "scroll" + dim,
			client = "client" + dim;
		return Math.max(0, _isRoot(element) ? Math.max(_docElement[scroll], _body[scroll]) - (_win["inner" + dim] || _docElement[client] || _body[client]) : element[scroll] - element[client]);
	},
	_recordMaxScrolls = (e, skipCurrent) => { //records _gsMaxScrollX and _gsMaxScrollY properties for the element and all ancestors up the chain so that we can cap it, otherwise dragging beyond the edges with autoScroll on can endlessly scroll.
		let x = _getMaxScroll(e, "x"),
			y = _getMaxScroll(e, "y");
		if (_isRoot(e)) {
			e = _windowProxy;
		} else {
			_recordMaxScrolls(e.parentNode, skipCurrent);
		}
		e._gsMaxScrollX = x;
		e._gsMaxScrollY = y;
		if (!skipCurrent) {
			e._gsScrollX = e.scrollLeft || 0;
			e._gsScrollY = e.scrollTop || 0;
		}
	},
	_setStyle = (element, property, value) => {
		let style = element.style;
		if (!style) {
			return;
		}
		if (_isUndefined(style[property])) {
			property = _checkPrefix(property, element) || property;
		}
		if (value == null) {
			style.removeProperty && style.removeProperty(property.replace(/([A-Z])/g, "-$1").toLowerCase());
		} else {
			style[property] = value;
		}
	},
	_getComputedStyle = element => _win.getComputedStyle((element instanceof Element) ? element : element.host || (element.parentNode || {}).host || element), //the "host" stuff helps to accommodate ShadowDom objects.

	_tempRect = {}, //reuse to reduce garbage collection tasks
	_parseRect = e => { //accepts a DOM element, a mouse event, or a rectangle object and returns the corresponding rectangle with left, right, width, height, top, and bottom properties
		if (e === _win) {
			_tempRect.left = _tempRect.top = 0;
			_tempRect.width = _tempRect.right = _docElement.clientWidth || e.innerWidth || _body.clientWidth || 0;
			_tempRect.height = _tempRect.bottom = ((e.innerHeight || 0) - 20 < _docElement.clientHeight) ? _docElement.clientHeight : e.innerHeight || _body.clientHeight || 0;
			return _tempRect;
		}
		let doc = e.ownerDocument || _doc,
			r = !_isUndefined(e.pageX) ? {left: e.pageX - _getDocScrollLeft(doc), top: e.pageY - _getDocScrollTop(doc), right: e.pageX - _getDocScrollLeft(doc) + 1, bottom: e.pageY - _getDocScrollTop(doc) + 1} : (!e.nodeType && !_isUndefined(e.left) && !_isUndefined(e.top)) ? e : _toArray(e)[0].getBoundingClientRect();
		if (_isUndefined(r.right) && !_isUndefined(r.width)) {
			r.right = r.left + r.width;
			r.bottom = r.top + r.height;
		} else if (_isUndefined(r.width)) { //some browsers don't include width and height properties. We can't just set them directly on r because some browsers throw errors, so create a new generic object.
			r = {width: r.right - r.left, height: r.bottom - r.top, right: r.right, left: r.left, bottom: r.bottom, top: r.top};
		}
		return r;
	},

	_dispatchEvent = (target, type, callbackName) => {
		let vars = target.vars,
			callback = vars[callbackName],
			listeners = target._listeners[type],
			result;
		if (_isFunction(callback)) {
			result = callback.apply(vars.callbackScope || target, vars[callbackName + "Params"] || [target.pointerEvent]);
		}
		if (listeners && target.dispatchEvent(type) === false) {
			result = false;
		}
		return result;
	},
	_getBounds = (target, context) => { //accepts any of the following: a DOM element, jQuery object, selector text, or an object defining bounds as {top, left, width, height} or {minX, maxX, minY, maxY}. Returns an object with left, top, width, and height properties.
		let e = _toArray(target)[0],
			top, left, offset;
		if (!e.nodeType && e !== _win) {
			if (!_isUndefined(target.left)) {
				offset = {x:0, y:0}; //_getOffsetTransformOrigin(context); //the bounds should be relative to the origin
				return {left: target.left - offset.x, top: target.top - offset.y, width: target.width, height: target.height};
			}
			left = target.min || target.minX || target.minRotation || 0;
			top = target.min || target.minY || 0;
			return {left:left, top:top, width:(target.max || target.maxX || target.maxRotation || 0) - left, height:(target.max || target.maxY || 0) - top};
		}
		return _getElementBounds(e, context);
	},
	_point1 = {}, //we reuse to minimize garbage collection tasks.
	_getElementBounds = (element, context) => {
		context = _toArray(context)[0];
		let isSVG = (element.getBBox && element.ownerSVGElement),
			doc = element.ownerDocument || _doc,
			left, right, top, bottom, matrix, p1, p2, p3, p4, bbox, width, height, cs;
		if (element === _win) {
			top = _getDocScrollTop(doc);
			left = _getDocScrollLeft(doc);
			right = left + (doc.documentElement.clientWidth || element.innerWidth || doc.body.clientWidth || 0);
			bottom = top + (((element.innerHeight || 0) - 20 < doc.documentElement.clientHeight) ? doc.documentElement.clientHeight : element.innerHeight || doc.body.clientHeight || 0); //some browsers (like Firefox) ignore absolutely positioned elements, and collapse the height of the documentElement, so it could be 8px, for example, if you have just an absolutely positioned div. In that case, we use the innerHeight to resolve this.
		} else if (context === _win || _isUndefined(context)) {
			return element.getBoundingClientRect();
		} else {
			left = top =  0;
			if (isSVG) {
				bbox = element.getBBox();
				width = bbox.width;
				height = bbox.height;
			} else {
				if (element.viewBox && (bbox = element.viewBox.baseVal)) {
					left = bbox.x || 0;
					top = bbox.y || 0;
					width = bbox.width;
					height = bbox.height;
				}
				if (!width) {
					cs = _getComputedStyle(element);
					bbox = cs.boxSizing === "border-box";
					width = (parseFloat(cs.width) || element.clientWidth || 0) + (bbox ? 0 : parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth));
					height = (parseFloat(cs.height) || element.clientHeight || 0) + (bbox ? 0 : parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth));
				}
			}
			right = width;
			bottom = height;
		}
		if (element === context) {
			return {left:left, top:top, width: right - left, height: bottom - top};
		}
		matrix = getGlobalMatrix(context, true).multiply(getGlobalMatrix(element));
		p1 = matrix.apply({x:left, y:top});
		p2 = matrix.apply({x:right, y:top});
		p3 = matrix.apply({x:right, y:bottom});
		p4 = matrix.apply({x:left, y:bottom});
		left = Math.min(p1.x, p2.x, p3.x, p4.x);
		top = Math.min(p1.y, p2.y, p3.y, p4.y);
		return {left: left, top: top, width: Math.max(p1.x, p2.x, p3.x, p4.x) - left, height: Math.max(p1.y, p2.y, p3.y, p4.y) - top};
	},
	_parseInertia = (draggable, snap, max, min, factor, forceZeroVelocity) => {
		let vars = {},
			a, i, l;
		if (snap) {
			if (factor !== 1 && snap instanceof Array) { //some data must be altered to make sense, like if the user passes in an array of rotational values in degrees, we must convert it to radians. Or for scrollLeft and scrollTop, we invert the values.
				vars.end = a = [];
				l = snap.length;
				if (_isObject(snap[0])) { //if the array is populated with objects, like points ({x:100, y:200}), make copies before multiplying by the factor, otherwise we'll mess up the originals and the user may reuse it elsewhere.
					for (i = 0; i < l; i++) {
						a[i] = _copy(snap[i], factor);
					}
				} else {
					for (i = 0; i < l; i++) {
						a[i] = snap[i] * factor;
					}
				}
				max += 1.1; //allow 1.1 pixels of wiggle room when snapping in order to work around some browser inconsistencies in the way bounds are reported which can make them roughly a pixel off. For example, if "snap:[-$('#menu').width(), 0]" was defined and #menu had a wrapper that was used as the bounds, some browsers would be one pixel off, making the minimum -752 for example when snap was [-753,0], thus instead of snapping to -753, it would snap to 0 since -753 was below the minimum.
				min -= 1.1;
			} else if (_isFunction(snap)) {
				vars.end = value => {
					let result = snap.call(draggable, value),
						copy, p;
					if (factor !== 1) {
						if (_isObject(result)) {
							copy = {};
							for (p in result) {
								copy[p] = result[p] * factor;
							}
							result = copy;
						} else {
							result *= factor;
						}
					}
					return result; //we need to ensure that we can scope the function call to the Draggable instance itself so that users can access important values like maxX, minX, maxY, minY, x, and y from within that function.
				};
			} else {
				vars.end = snap;
			}
		}
		if (max || max === 0) {
			vars.max = max;
		}
		if (min || min === 0) {
			vars.min = min;
		}
		if (forceZeroVelocity) {
			vars.velocity = 0;
		}
		return vars;
	},
	_isClickable = element => { //sometimes it's convenient to mark an element as clickable by adding a data-clickable="true" attribute (in which case we won't preventDefault() the mouse/touch event). This method checks if the element is an <a>, <input>, or <button> or has the data-clickable or contentEditable attribute set to true (or any of its parent elements).
		let data;
		return (!element || !element.getAttribute || element === _body) ? false : ((data = element.getAttribute("data-clickable")) === "true" || (data !== "false" && (_clickableTagExp.test(element.nodeName + "") || element.getAttribute("contentEditable") === "true"))) ? true : _isClickable(element.parentNode);
	},
	_setSelectable = (elements, selectable) => {
		let i = elements.length,
			e;
		while (i--) {
			e = elements[i];
			e.ondragstart = e.onselectstart = selectable ? null : _emptyFunc;
			gsap.set(e, {lazy:true, userSelect: (selectable ? "text" : "none")});
		}
	},
	_isFixed = element => {
		if (_getComputedStyle(element).position === "fixed") {
			return true;
		}
		element = element.parentNode;
		if (element && element.nodeType === 1) { // avoid document fragments which will throw an error.
			return _isFixed(element);
		}
	},
	_supports3D, _addPaddingBR,

	//The ScrollProxy class wraps an element's contents into another div (we call it "content") that we either add padding when necessary or apply a translate3d() transform in order to overscroll (scroll past the boundaries). This allows us to simply set the scrollTop/scrollLeft (or top/left for easier reverse-axis orientation, which is what we do in Draggable) and it'll do all the work for us. For example, if we tried setting scrollTop to -100 on a normal DOM element, it wouldn't work - it'd look the same as setting it to 0, but if we set scrollTop of a ScrollProxy to -100, it'll give the correct appearance by either setting paddingTop of the wrapper to 100 or applying a 100-pixel translateY.
	ScrollProxy = function(element, vars) {
		element = gsap.utils.toArray(element)[0];
		vars = vars || {};
		let content = document.createElement("div"),
			style = content.style,
			node = element.firstChild,
			offsetTop = 0,
			offsetLeft = 0,
			prevTop = element.scrollTop,
			prevLeft = element.scrollLeft,
			scrollWidth = element.scrollWidth,
			scrollHeight = element.scrollHeight,
			extraPadRight = 0,
			maxLeft = 0,
			maxTop = 0,
			elementWidth, elementHeight, contentHeight, nextNode, transformStart, transformEnd;
		if (_supports3D && vars.force3D !== false) {
			transformStart = "translate3d(";
			transformEnd = "px,0px)";
		} else if (_transformProp) {
			transformStart = "translate(";
			transformEnd = "px)";
		}
		this.scrollTop = function(value, force) {
			if (!arguments.length) {
				return -this.top();
			}
			this.top(-value, force);
		};
		this.scrollLeft = function(value, force) {
			if (!arguments.length) {
				return -this.left();
			}
			this.left(-value, force);
		};
		this.left = function(value, force) {
			if (!arguments.length) {
				return -(element.scrollLeft + offsetLeft);
			}
			let dif = element.scrollLeft - prevLeft,
				oldOffset = offsetLeft;
			if ((dif > 2 || dif < -2) && !force) { //if the user interacts with the scrollbar (or something else scrolls it, like the mouse wheel), we should kill any tweens of the ScrollProxy.
				prevLeft = element.scrollLeft;
				gsap.killTweensOf(this, {left:1, scrollLeft:1});
				this.left(-prevLeft);
				if (vars.onKill) {
					vars.onKill();
				}
				return;
			}
			value = -value; //invert because scrolling works in the opposite direction
			if (value < 0) {
				offsetLeft = (value - 0.5) | 0;
				value = 0;
			} else if (value > maxLeft) {
				offsetLeft = (value - maxLeft) | 0;
				value = maxLeft;
			} else {
				offsetLeft = 0;
			}
			if (offsetLeft || oldOffset) {
				if (!this._skip) {
					style[_transformProp] = transformStart + -offsetLeft + "px," + -offsetTop + transformEnd;
				}
				if (offsetLeft + extraPadRight >= 0) {
					style.paddingRight =  offsetLeft + extraPadRight + "px";
				}
			}
			element.scrollLeft = value | 0;
			prevLeft = element.scrollLeft; //don't merge this with the line above because some browsers adjust the scrollLeft after it's set, so in order to be 100% accurate in tracking it, we need to ask the browser to report it.
		};
		this.top = function(value, force) {
			if (!arguments.length) {
				return -(element.scrollTop + offsetTop);
			}
			let dif = element.scrollTop - prevTop,
				oldOffset = offsetTop;
			if ((dif > 2 || dif < -2) && !force) { //if the user interacts with the scrollbar (or something else scrolls it, like the mouse wheel), we should kill any tweens of the ScrollProxy.
				prevTop = element.scrollTop;
				gsap.killTweensOf(this, {top:1, scrollTop:1});
				this.top(-prevTop);
				if (vars.onKill) {
					vars.onKill();
				}
				return;
			}
			value = -value; //invert because scrolling works in the opposite direction
			if (value < 0) {
				offsetTop = (value - 0.5) | 0;
				value = 0;
			} else if (value > maxTop) {
				offsetTop = (value - maxTop) | 0;
				value = maxTop;
			} else {
				offsetTop = 0;
			}
			if (offsetTop || oldOffset) {
				if (!this._skip) {
					style[_transformProp] = transformStart + -offsetLeft + "px," + -offsetTop + transformEnd;
				}
			}
			element.scrollTop = value | 0;
			prevTop = element.scrollTop;
		};

		this.maxScrollTop = () => maxTop;
		this.maxScrollLeft = () => maxLeft;

		this.disable = function() {
			node = content.firstChild;
			while (node) {
				nextNode = node.nextSibling;
				element.appendChild(node);
				node = nextNode;
			}
			if (element === content.parentNode) { //in case disable() is called when it's already disabled.
				element.removeChild(content);
			}
		};
		this.enable = function() {
			node = element.firstChild;
			if (node === content) {
				return;
			}
			while (node) {
				nextNode = node.nextSibling;
				content.appendChild(node);
				node = nextNode;
			}
			element.appendChild(content);
			this.calibrate();
		};
		this.calibrate = function(force) {
			let widthMatches = (element.clientWidth === elementWidth),
				cs, x, y;
			prevTop = element.scrollTop;
			prevLeft = element.scrollLeft;
			if (widthMatches && element.clientHeight === elementHeight && content.offsetHeight === contentHeight && scrollWidth === element.scrollWidth && scrollHeight === element.scrollHeight && !force) {
				return; //no need to recalculate things if the width and height haven't changed.
			}
			if (offsetTop || offsetLeft) {
				x = this.left();
				y = this.top();
				this.left(-element.scrollLeft);
				this.top(-element.scrollTop);
			}
			cs = _getComputedStyle(element);
			//first, we need to remove any width constraints to see how the content naturally flows so that we can see if it's wider than the containing element. If so, we've got to record the amount of overage so that we can apply that as padding in order for browsers to correctly handle things. Then we switch back to a width of 100% (without that, some browsers don't flow the content correctly)
			if (!widthMatches || force) {
				style.display = "block";
				style.width = "auto";
				style.paddingRight = "0px";
				extraPadRight = Math.max(0, element.scrollWidth - element.clientWidth);
				//if the content is wider than the container, we need to add the paddingLeft and paddingRight in order for things to behave correctly.
				if (extraPadRight) {
					extraPadRight += parseFloat(cs.paddingLeft) + (_addPaddingBR ? parseFloat(cs.paddingRight) : 0);
				}
			}
			style.display = "inline-block";
			style.position = "relative";
			style.overflow = "visible";
			style.verticalAlign = "top";
			style.boxSizing = "content-box";
			style.width = "100%";
			style.paddingRight = extraPadRight + "px";
			//some browsers neglect to factor in the bottom padding when calculating the scrollHeight, so we need to add that padding to the content when that happens. Allow a 2px margin for error
			if (_addPaddingBR) {
				style.paddingBottom = cs.paddingBottom;
			}
			elementWidth = element.clientWidth;
			elementHeight = element.clientHeight;
			scrollWidth = element.scrollWidth;
			scrollHeight = element.scrollHeight;
			maxLeft = element.scrollWidth - elementWidth;
			maxTop = element.scrollHeight - elementHeight;
			contentHeight = content.offsetHeight;
			style.display = "block";
			if (x || y) {
				this.left(x);
				this.top(y);
			}
		};
		this.content = content;
		this.element = element;
		this._skip = false;
		this.enable();
	},
	_initCore = required => {
		if (_windowExists() && document.body) {
			let nav = window && window.navigator;
			_win = window;
			_doc = document;
			_docElement = _doc.documentElement;
			_body = _doc.body;
			_tempDiv = _createElement("div");
			_supportsPointer = !!window.PointerEvent;
			_placeholderDiv = _createElement("div");
			_placeholderDiv.style.cssText = "visibility:hidden;height:1px;top:-1px;pointer-events:none;position:relative;clear:both;cursor:grab";
			_defaultCursor = _placeholderDiv.style.cursor === "grab" ? "grab" : "move";
			_isAndroid = (nav && nav.userAgent.toLowerCase().indexOf("android") !== -1); //Android handles touch events in an odd way and it's virtually impossible to "feature test" so we resort to UA sniffing
			_isTouchDevice = (("ontouchstart" in _docElement) && ("orientation" in _win)) || (nav && (nav.MaxTouchPoints > 0 || nav.msMaxTouchPoints > 0));
			_addPaddingBR = (function() { //this function is in charge of analyzing browser behavior related to padding. It sets the _addPaddingBR to true if the browser doesn't normally factor in the bottom or right padding on the element inside the scrolling area, and it sets _addPaddingLeft to true if it's a browser that requires the extra offset (offsetLeft) to be added to the paddingRight (like Opera).
				let div = _createElement("div"),
					child = _createElement("div"),
					childStyle = child.style,
					parent = _body,
					val;
				childStyle.display = "inline-block";
				childStyle.position = "relative";
				div.style.cssText = "width:90px;height:40px;padding:10px;overflow:auto;visibility:hidden";
				div.appendChild(child);
				parent.appendChild(div);
				val = (child.offsetHeight + 18 > div.scrollHeight); //div.scrollHeight should be child.offsetHeight + 20 because of the 10px of padding on each side, but some browsers ignore one side. We allow a 2px margin of error.
				parent.removeChild(div);
				return val;
			}());
			_touchEventLookup = (function(types) { //we create an object that makes it easy to translate touch event types into their "pointer" counterparts if we're in a browser that uses those instead. Like IE10 uses "MSPointerDown" instead of "touchstart", for example.
				let standard = types.split(","),
					converted = ("onpointerdown" in _tempDiv ? "pointerdown,pointermove,pointerup,pointercancel" : "onmspointerdown" in _tempDiv ? "MSPointerDown,MSPointerMove,MSPointerUp,MSPointerCancel" : types).split(","),
					obj = {},
					i = 4;
				while (--i > -1) {
					obj[standard[i]] = converted[i];
					obj[converted[i]] = standard[i];
				}
				//to avoid problems in iOS 9, test to see if the browser supports the "passive" option on addEventListener().
				try {
					_docElement.addEventListener("test", null, Object.defineProperty({}, "passive", {
						get: function () {
							_supportsPassive = 1;
						}
					}));
				} catch (e) {}
				return obj;
			}("touchstart,touchmove,touchend,touchcancel"));
			_addListener(_doc, "touchcancel", _emptyFunc); //some older Android devices intermittently stop dispatching "touchmove" events if we don't listen for "touchcancel" on the document. Very strange indeed.
			_addListener(_win, "touchmove", _emptyFunc); //works around Safari bugs that still allow the page to scroll even when we preventDefault() on the touchmove event.
			_body && _body.addEventListener("touchstart", _emptyFunc); //works around Safari bug: https://gsap.com/forums/topic/21450-draggable-in-iframe-on-mobile-is-buggy/
			_addListener(_doc, "contextmenu", function() {
				for (let p in _lookup) {
					if (_lookup[p].isPressed) {
						_lookup[p].endDrag();
					}
				}
			});
			gsap = _coreInitted = _getGSAP();
		}
		if (gsap) {
			InertiaPlugin = gsap.plugins.inertia;
			_context = gsap.core.context || function() {};
			_checkPrefix = gsap.utils.checkPrefix;
			_transformProp = _checkPrefix(_transformProp);
			_transformOriginProp = _checkPrefix(_transformOriginProp);
			_toArray = gsap.utils.toArray;
			_getStyleSaver = gsap.core.getStyleSaver;
			_supports3D = !!_checkPrefix("perspective");
		} else if (required) {
			console.warn("Please gsap.registerPlugin(Draggable)");
		}
	};






class EventDispatcher {

	constructor(target) {
		this._listeners = {};
		this.target = target || this;
	}

	addEventListener(type, callback) {
		let list = this._listeners[type] || (this._listeners[type] = []);
		if (!~list.indexOf(callback)) {
			list.push(callback);
		}
	}

	removeEventListener(type, callback) {
		let list = this._listeners[type],
			i = (list && list.indexOf(callback));
		(i >= 0) && list.splice(i, 1);
	}

	dispatchEvent(type) {
		let result;
		(this._listeners[type] || []).forEach(callback => (callback.call(this, {type: type, target: this.target}) === false) && (result = false));
		return result; //if any of the callbacks return false, pass that along.
	}
}









export class Draggable extends EventDispatcher {

	constructor(target, vars) {
		super();
		_coreInitted || _initCore(1);
		target = _toArray(target)[0]; //in case the target is a selector object or selector text
		this.styles = _getStyleSaver && _getStyleSaver(target, "transform,left,top");
		if (!InertiaPlugin) {
			InertiaPlugin = gsap.plugins.inertia;
		}
		this.vars = vars = _copy(vars || {});
		this.target = target;
		this.x = this.y = this.rotation = 0;
		this.dragResistance = parseFloat(vars.dragResistance) || 0;
		this.edgeResistance = isNaN(vars.edgeResistance) ? 1 : parseFloat(vars.edgeResistance) || 0;
		this.lockAxis = vars.lockAxis;
		this.autoScroll = vars.autoScroll || 0;
		this.lockedAxis = null;
		this.allowEventDefault = !!vars.allowEventDefault;

		gsap.getProperty(target, "x"); // to ensure that transforms are instantiated.

		let type = (vars.type || "x,y").toLowerCase(),
			xyMode = (~type.indexOf("x") || ~type.indexOf("y")),
			rotationMode = (type.indexOf("rotation") !== -1),
			xProp = rotationMode ? "rotation" : xyMode ? "x" : "left",
			yProp = xyMode ? "y" : "top",
			allowX = !!(~type.indexOf("x") || ~type.indexOf("left") || type === "scroll"),
			allowY = !!(~type.indexOf("y") || ~type.indexOf("top") || type === "scroll"),
			minimumMovement = vars.minimumMovement || 2,
			self = this,
			triggers = _toArray(vars.trigger || vars.handle || target),
			killProps = {},
			dragEndTime = 0,
			checkAutoScrollBounds = false,
			autoScrollMarginTop = vars.autoScrollMarginTop || 40,
			autoScrollMarginRight = vars.autoScrollMarginRight || 40,
			autoScrollMarginBottom = vars.autoScrollMarginBottom || 40,
			autoScrollMarginLeft = vars.autoScrollMarginLeft || 40,
			isClickable = vars.clickableTest || _isClickable,
			clickTime = 0,
			gsCache = target._gsap || gsap.core.getCache(target),
			isFixed = _isFixed(target),
			getPropAsNum = (property, unit) => parseFloat(gsCache.get(target, property, unit)),
			ownerDoc = target.ownerDocument || _doc,
			enabled, scrollProxy, startPointerX, startPointerY, startElementX, startElementY, hasBounds, hasDragCallback, hasMoveCallback, maxX, minX, maxY, minY, touch, touchID, rotationOrigin, dirty, old, snapX, snapY, snapXY, isClicking, touchEventTarget, matrix, interrupted, allowNativeTouchScrolling, touchDragAxis, isDispatching, clickDispatch, trustedClickDispatch, isPreventingDefault, innerMatrix, dragged,

			onContextMenu = e => { //used to prevent long-touch from triggering a context menu.
				// (self.isPressed && e.which < 2) && self.endDrag() // previously ended drag when context menu was triggered, but instead we should just stop propagation and prevent the default event behavior.
				_preventDefault(e);
				e.stopImmediatePropagation && e.stopImmediatePropagation();
				return false;
			},

			//this method gets called on every tick of TweenLite.ticker which allows us to synchronize the renders to the core engine (which is typically synchronized with the display refresh via requestAnimationFrame). This is an optimization - it's better than applying the values inside the "mousemove" or "touchmove" event handler which may get called many times inbetween refreshes.
			render = suppressEvents => {
				if (self.autoScroll && self.isDragging && (checkAutoScrollBounds || dirty)) {
					let e = target,
						autoScrollFactor = self.autoScroll * 15, //multiplying by 15 just gives us a better "feel" speed-wise.
						parent, isRoot, rect, pointerX, pointerY, changeX, changeY, gap;
					checkAutoScrollBounds = false;
					_windowProxy.scrollTop = ((_win.pageYOffset != null) ? _win.pageYOffset : (ownerDoc.documentElement.scrollTop != null) ? ownerDoc.documentElement.scrollTop : ownerDoc.body.scrollTop);
					_windowProxy.scrollLeft = ((_win.pageXOffset != null) ? _win.pageXOffset : (ownerDoc.documentElement.scrollLeft != null) ? ownerDoc.documentElement.scrollLeft : ownerDoc.body.scrollLeft);
					pointerX = self.pointerX - _windowProxy.scrollLeft;
					pointerY = self.pointerY - _windowProxy.scrollTop;
					while (e && !isRoot) { //walk up the chain and sense wherever the pointer is within 40px of an edge that's scrollable.
						isRoot = _isRoot(e.parentNode);
						parent = isRoot ? _windowProxy : e.parentNode;
						rect = isRoot ? {bottom:Math.max(_docElement.clientHeight, _win.innerHeight || 0), right: Math.max(_docElement.clientWidth, _win.innerWidth || 0), left:0, top:0} : parent.getBoundingClientRect();
						changeX = changeY = 0;
						if (allowY) {
							gap = parent._gsMaxScrollY - parent.scrollTop;
							if (gap < 0) {
								changeY = gap;
							} else if (pointerY > rect.bottom - autoScrollMarginBottom && gap) {
								checkAutoScrollBounds = true;
								changeY = Math.min(gap, (autoScrollFactor * (1 - Math.max(0, (rect.bottom - pointerY)) / autoScrollMarginBottom)) | 0);
							} else if (pointerY < rect.top + autoScrollMarginTop && parent.scrollTop) {
								checkAutoScrollBounds = true;
								changeY = -Math.min(parent.scrollTop, (autoScrollFactor * (1 - Math.max(0, (pointerY - rect.top)) / autoScrollMarginTop)) | 0);
							}
							if (changeY) {
								parent.scrollTop += changeY;
							}
						}
						if (allowX) {
							gap = parent._gsMaxScrollX - parent.scrollLeft;
							if (gap < 0) {
								changeX = gap;
							} else if (pointerX > rect.right - autoScrollMarginRight && gap) {
								checkAutoScrollBounds = true;
								changeX = Math.min(gap, (autoScrollFactor * (1 - Math.max(0, (rect.right - pointerX)) / autoScrollMarginRight)) | 0);
							} else if (pointerX < rect.left + autoScrollMarginLeft && parent.scrollLeft) {
								checkAutoScrollBounds = true;
								changeX = -Math.min(parent.scrollLeft, (autoScrollFactor * (1 - Math.max(0, (pointerX - rect.left)) / autoScrollMarginLeft)) | 0);
							}
							if (changeX) {
								parent.scrollLeft += changeX;
							}
						}

						if (isRoot && (changeX || changeY)) {
							_win.scrollTo(parent.scrollLeft, parent.scrollTop);
							setPointerPosition(self.pointerX + changeX, self.pointerY + changeY);
						}
						e = parent;
					}
				}
				if (dirty) {
					let {x, y} = self;
					if (rotationMode) {
						self.deltaX = x - parseFloat(gsCache.rotation);
						self.rotation = x;
						gsCache.rotation = x + "deg";
						gsCache.renderTransform(1, gsCache);
					} else {
						if (scrollProxy) {
							if (allowY) {
								self.deltaY = y - scrollProxy.top();
								scrollProxy.top(y);
							}
							if (allowX) {
								self.deltaX = x - scrollProxy.left();
								scrollProxy.left(x);
							}
						} else if (xyMode) {
							if (allowY) {
								self.deltaY = y - parseFloat(gsCache.y);
								gsCache.y = y + "px";
							}
							if (allowX) {
								self.deltaX = x - parseFloat(gsCache.x);
								gsCache.x = x + "px";
							}
							gsCache.renderTransform(1, gsCache);
						} else {
							if (allowY) {
								self.deltaY = y - parseFloat(target.style.top || 0);
								target.style.top = y + "px";
							}
							if (allowX) {
								self.deltaX = x - parseFloat(target.style.left || 0);
								target.style.left = x + "px";
							}
						}
					}
					if (hasDragCallback && !suppressEvents && !isDispatching) {
						isDispatching = true; //in case onDrag has an update() call (avoid endless loop)
						if (_dispatchEvent(self, "drag", "onDrag") === false) {
							if (allowX) {
								self.x -= self.deltaX;
							}
							if (allowY) {
								self.y -= self.deltaY;
							}
							render(true);
						}
						isDispatching = false;
					}
				}
				dirty = false;
			},

			//copies the x/y from the element (whether that be transforms, top/left, or ScrollProxy's top/left) to the Draggable's x and y (and rotation if necessary) properties so that they reflect reality and it also (optionally) applies any snapping necessary. This is used by the InertiaPlugin tween in an onUpdate to ensure things are synced and snapped.
			syncXY = (skipOnUpdate, skipSnap) => {
				let { x, y } = self,
					snappedValue, cs;
				if (!target._gsap) { //just in case the _gsap cache got wiped, like if the user called clearProps on the transform or something (very rare).
					gsCache = gsap.core.getCache(target);
				}
				gsCache.uncache && gsap.getProperty(target, "x"); // trigger a re-cache
				if (xyMode) {
					self.x = parseFloat(gsCache.x);
					self.y = parseFloat(gsCache.y);
				} else if (rotationMode) {
					self.x = self.rotation = parseFloat(gsCache.rotation);
				} else if (scrollProxy) {
					self.y = scrollProxy.top();
					self.x = scrollProxy.left();
				} else {
					self.y = parseFloat(target.style.top || ((cs = _getComputedStyle(target)) && cs.top)) || 0;
					self.x = parseFloat(target.style.left || (cs || {}).left) || 0;
				}
				if ((snapX || snapY || snapXY) && !skipSnap && (self.isDragging || self.isThrowing)) {
					if (snapXY) {
						_temp1.x = self.x;
						_temp1.y = self.y;
						snappedValue = snapXY(_temp1);
						if (snappedValue.x !== self.x) {
							self.x = snappedValue.x;
							dirty = true;
						}
						if (snappedValue.y !== self.y) {
							self.y = snappedValue.y;
							dirty = true;
						}
					}
					if (snapX) {
						snappedValue = snapX(self.x);
						if (snappedValue !== self.x) {
							self.x = snappedValue;
							if (rotationMode) {
								self.rotation = snappedValue;
							}
							dirty = true;
						}
					}
					if (snapY) {
						snappedValue = snapY(self.y);
						if (snappedValue !== self.y) {
							self.y = snappedValue;
						}
						dirty = true;
					}
				}
				dirty && render(true);
				if (!skipOnUpdate) {
					self.deltaX = self.x - x;
					self.deltaY = self.y - y;
					_dispatchEvent(self, "throwupdate", "onThrowUpdate");
				}
			},

			buildSnapFunc = (snap, min, max, factor) => {
				if (min == null) {
					min = -_bigNum;
				}
				if (max == null) {
					max = _bigNum;
				}
				if (_isFunction(snap)) {
					return n => {
						let edgeTolerance = !self.isPressed ? 1 : 1 - self.edgeResistance; //if we're tweening, disable the edgeTolerance because it's already factored into the tweening values (we don't want to apply it multiple times)
						return snap.call(self, (n > max ? max + (n - max) * edgeTolerance : (n < min) ? min + (n - min) * edgeTolerance : n) * factor) * factor;
					};
				}
				if (_isArray(snap)) {
					return n => {
						let i = snap.length,
							closest = 0,
							absDif = _bigNum,
							val, dif;
						while (--i > -1) {
							val = snap[i];
							dif = val - n;
							if (dif < 0) {
								dif = -dif;
							}
							if (dif < absDif && val >= min && val <= max) {
								closest = i;
								absDif = dif;
							}
						}
						return snap[closest];
					};
				}
				return isNaN(snap) ? n => n : () => snap * factor;
			},

			buildPointSnapFunc = (snap, minX, maxX, minY, maxY, radius, factor) => {
				radius = (radius && radius < _bigNum) ? radius * radius : _bigNum; //so we don't have to Math.sqrt() in the functions. Performance optimization.
				if (_isFunction(snap)) {
					return point => {
						let edgeTolerance = !self.isPressed ? 1 : 1 - self.edgeResistance,
							x = point.x,
							y = point.y,
							result, dx, dy; //if we're tweening, disable the edgeTolerance because it's already factored into the tweening values (we don't want to apply it multiple times)
						point.x = x = (x > maxX ? maxX + (x - maxX) * edgeTolerance : (x < minX) ? minX + (x - minX) * edgeTolerance : x);
						point.y = y = (y > maxY ? maxY + (y - maxY) * edgeTolerance : (y < minY) ? minY + (y - minY) * edgeTolerance : y);
						result = snap.call(self, point);
						if (result !== point) {
							point.x = result.x;
							point.y = result.y;
						}
						if (factor !== 1) {
							point.x *= factor;
							point.y *= factor;
						}
						if (radius < _bigNum) {
							dx = point.x - x;
							dy = point.y - y;
							if (dx * dx + dy * dy > radius) {
								point.x = x;
								point.y = y;
							}
						}
						return point;
					};
				}
				if (_isArray(snap)) {
					return p => {
						let i = snap.length,
							closest = 0,
							minDist = _bigNum,
							x, y, point, dist;
						while (--i > -1) {
							point = snap[i];
							x = point.x - p.x;
							y = point.y - p.y;
							dist = x * x + y * y;
							if (dist < minDist) {
								closest = i;
								minDist = dist;
							}
						}
						return (minDist <= radius) ? snap[closest] : p;
					};
				}
				return n => n;
			},

			calculateBounds = () => {
				let bounds, targetBounds, snap, snapIsRaw;
				hasBounds = false;
				if (scrollProxy) {
					scrollProxy.calibrate();
					self.minX = minX = -scrollProxy.maxScrollLeft();
					self.minY = minY = -scrollProxy.maxScrollTop();
					self.maxX = maxX = self.maxY = maxY = 0;
					hasBounds = true;
				} else if (!!vars.bounds) {
					bounds = _getBounds(vars.bounds, target.parentNode); //could be a selector/jQuery object or a DOM element or a generic object like {top:0, left:100, width:1000, height:800} or {minX:100, maxX:1100, minY:0, maxY:800}
					if (rotationMode) {
						self.minX = minX = bounds.left;
						self.maxX = maxX = bounds.left + bounds.width;
						self.minY = minY = self.maxY = maxY = 0;
					} else if (!_isUndefined(vars.bounds.maxX) || !_isUndefined(vars.bounds.maxY)) {
						bounds = vars.bounds;
						self.minX = minX = bounds.minX;
						self.minY = minY = bounds.minY;
						self.maxX = maxX = bounds.maxX;
						self.maxY = maxY = bounds.maxY;
					} else {
						targetBounds = _getBounds(target, target.parentNode);
						self.minX = minX = Math.round(getPropAsNum(xProp, "px") + bounds.left - targetBounds.left);
						self.minY = minY = Math.round(getPropAsNum(yProp, "px") + bounds.top - targetBounds.top);
						self.maxX = maxX = Math.round(minX + (bounds.width - targetBounds.width));
						self.maxY = maxY = Math.round(minY + (bounds.height - targetBounds.height));
					}
					if (minX > maxX) {
						self.minX = maxX;
						self.maxX = maxX = minX;
						minX = self.minX;
					}
					if (minY > maxY) {
						self.minY = maxY;
						self.maxY = maxY = minY;
						minY = self.minY;
					}
					if (rotationMode) {
						self.minRotation = minX;
						self.maxRotation = maxX;
					}
					hasBounds = true;
				}
				if (vars.liveSnap) {
					snap = (vars.liveSnap === true) ? (vars.snap || {}) : vars.liveSnap;
					snapIsRaw = (_isArray(snap) || _isFunction(snap));
					if (rotationMode) {
						snapX = buildSnapFunc((snapIsRaw ? snap : snap.rotation), minX, maxX, 1);
						snapY = null;
					} else {
						if (snap.points) {
							snapXY = buildPointSnapFunc((snapIsRaw ? snap : snap.points), minX, maxX, minY, maxY, snap.radius, scrollProxy ? -1 : 1);
						} else {
							if (allowX) {
								snapX = buildSnapFunc((snapIsRaw ? snap : snap.x || snap.left || snap.scrollLeft), minX, maxX, scrollProxy ? -1 : 1);
							}
							if (allowY) {
								snapY = buildSnapFunc((snapIsRaw ? snap : snap.y || snap.top || snap.scrollTop), minY, maxY, scrollProxy ? -1 : 1);
							}
						}
					}
				}
			},

			onThrowComplete = () => {
				self.isThrowing = false;
				_dispatchEvent(self, "throwcomplete", "onThrowComplete");
			},
			onThrowInterrupt = () => {
				self.isThrowing = false;
			},

			animate = (inertia, forceZeroVelocity) => {
				let snap, snapIsRaw, tween, overshootTolerance;
				if (inertia && InertiaPlugin) {
					if (inertia === true) {
						snap = vars.snap || vars.liveSnap || {};
						snapIsRaw = (_isArray(snap) || _isFunction(snap));
						inertia = {resistance:(vars.throwResistance || vars.resistance || 1000) / (rotationMode ? 10 : 1)};
						if (rotationMode) {
							inertia.rotation = _parseInertia(self, snapIsRaw ? snap : snap.rotation, maxX, minX, 1, forceZeroVelocity);
						} else {
							if (allowX) {
								inertia[xProp] = _parseInertia(self, snapIsRaw ? snap : snap.points || snap.x || snap.left, maxX, minX, scrollProxy ? -1 : 1, forceZeroVelocity || (self.lockedAxis === "x"));
							}
							if (allowY) {
								inertia[yProp] = _parseInertia(self, snapIsRaw ? snap : snap.points || snap.y || snap.top, maxY, minY, scrollProxy ? -1 : 1, forceZeroVelocity || (self.lockedAxis === "y"));
							}
							if (snap.points || (_isArray(snap) && _isObject(snap[0]))) {
								inertia.linkedProps = xProp + "," + yProp;
								inertia.radius = snap.radius; //note: we also disable liveSnapping while throwing if there's a "radius" defined, otherwise it looks weird to have the item thrown past a snapping point but live-snapping mid-tween. We do this by altering the onUpdateParams so that "skipSnap" parameter is true for syncXY.
							}
						}
					}
					self.isThrowing = true;
					overshootTolerance = (!isNaN(vars.overshootTolerance)) ? vars.overshootTolerance : (vars.edgeResistance === 1) ? 0 : (1 - self.edgeResistance) + 0.2;
					if (!inertia.duration) {
						inertia.duration = {max: Math.max(vars.minDuration || 0, ("maxDuration" in vars) ? vars.maxDuration : 2), min: (!isNaN(vars.minDuration) ? vars.minDuration : (overshootTolerance === 0 || (_isObject(inertia) && inertia.resistance > 1000)) ? 0 : 0.5), overshoot: overshootTolerance};
					}
					self.tween = tween = gsap.to(scrollProxy || target, {
						inertia: inertia,
						data: "_draggable",
						inherit: false,
						onComplete: onThrowComplete,
						onInterrupt: onThrowInterrupt,
						onUpdate: (vars.fastMode ? _dispatchEvent : syncXY),
						onUpdateParams: (vars.fastMode ? [self, "onthrowupdate", "onThrowUpdate"] : (snap && snap.radius) ? [false, true] : [])
					});
					if (!vars.fastMode) {
						if (scrollProxy) {
							scrollProxy._skip = true; // Microsoft browsers have a bug that causes them to briefly render the position incorrectly (it flashes to the end state when we seek() the tween even though we jump right back to the current position, and this only seems to happen when we're affecting both top and left), so we set a _suspendTransforms flag to prevent it from actually applying the values in the ScrollProxy.
						}
						tween.render(1e9, true, true); // force to the end. Remember, the duration will likely change upon initting because that's when InertiaPlugin calculates it.
						syncXY(true, true);
						self.endX = self.x;
						self.endY = self.y;
						if (rotationMode) {
							self.endRotation = self.x;
						}
						tween.play(0);
						syncXY(true, true);
						if (scrollProxy) {
							scrollProxy._skip = false; //Microsoft browsers have a bug that causes them to briefly render the position incorrectly (it flashes to the end state when we seek() the tween even though we jump right back to the current position, and this only seems to happen when we're affecting both top and left), so we set a _suspendTransforms flag to prevent it from actually applying the values in the ScrollProxy.
						}
					}
				} else if (hasBounds) {
					self.applyBounds();
				}
			},

			updateMatrix = shiftStart => {
				let start = matrix,
					p;
				matrix = getGlobalMatrix(target.parentNode, true);
				if (shiftStart && self.isPressed && !matrix.equals(start || new Matrix2D())) { //if the matrix changes WHILE the element is pressed, we must adjust the startPointerX and startPointerY accordingly, so we invert the original matrix and figure out where the pointerX and pointerY were in the global space, then apply the new matrix to get the updated coordinates.
					p = start.inverse().apply({x:startPointerX, y:startPointerY});
					matrix.apply(p, p);
					startPointerX = p.x;
					startPointerY = p.y;
				}
				if (matrix.equals(_identityMatrix)) { //if there are no transforms, we can optimize performance by not factoring in the matrix
					matrix = null;
				}
			},

			recordStartPositions = () => {
				let edgeTolerance = 1 - self.edgeResistance,
					offsetX = isFixed ? _getDocScrollLeft(ownerDoc) : 0,
					offsetY = isFixed ? _getDocScrollTop(ownerDoc) : 0,
					parsedOrigin, x, y;
				if (xyMode) { // in case the user set it as a different unit, like animating the x to "100%". We must convert it back to px!
					gsCache.x = getPropAsNum(xProp, "px") + "px";
					gsCache.y = getPropAsNum(yProp, "px") + "px";
					gsCache.renderTransform();
				}
				updateMatrix(false);
				_point1.x = self.pointerX - offsetX;
				_point1.y = self.pointerY - offsetY;
				matrix && matrix.apply(_point1, _point1);
				startPointerX = _point1.x; //translate to local coordinate system
				startPointerY = _point1.y;
				if (dirty) {
					setPointerPosition(self.pointerX, self.pointerY);
					render(true);
				}
				innerMatrix = getGlobalMatrix(target);
				if (scrollProxy) {
					calculateBounds();
					startElementY = scrollProxy.top();
					startElementX = scrollProxy.left();
				} else {
					//if the element is in the process of tweening, don't force snapping to occur because it could make it jump. Imagine the user throwing, then before it's done, clicking on the element in its inbetween state.
					if (isTweening()) {
						syncXY(true, true);
						calculateBounds();
					} else {
						self.applyBounds();
					}
					if (rotationMode) {
						parsedOrigin = target.ownerSVGElement ? [gsCache.xOrigin - target.getBBox().x, gsCache.yOrigin - target.getBBox().y] : (_getComputedStyle(target)[_transformOriginProp] || "0 0").split(" ");
						rotationOrigin = self.rotationOrigin = getGlobalMatrix(target).apply({x: parseFloat(parsedOrigin[0]) || 0, y: parseFloat(parsedOrigin[1]) || 0});
						syncXY(true, true);
						x = self.pointerX - rotationOrigin.x - offsetX;
						y = rotationOrigin.y - self.pointerY + offsetY;
						startElementX = self.x; //starting rotation (x always refers to rotation in type:"rotation", measured in degrees)
						startElementY = self.y = Math.atan2(y, x) * _RAD2DEG;
					} else {
						//parent = !isFixed && target.parentNode;
						//startScrollTop = parent ? parent.scrollTop || 0 : 0;
						//startScrollLeft = parent ? parent.scrollLeft || 0 : 0;
						startElementY = getPropAsNum(yProp, "px"); //record the starting top and left values so that we can just add the mouse's movement to them later.
						startElementX = getPropAsNum(xProp, "px");
					}
				}

				if (hasBounds && edgeTolerance) {
					if (startElementX > maxX) {
						startElementX = maxX + (startElementX - maxX) / edgeTolerance;
					} else if (startElementX < minX) {
						startElementX = minX - (minX - startElementX) / edgeTolerance;
					}
					if (!rotationMode) {
						if (startElementY > maxY) {
							startElementY = maxY + (startElementY - maxY) / edgeTolerance;
						} else if (startElementY < minY) {
							startElementY = minY - (minY - startElementY) / edgeTolerance;
						}
					}
				}
				self.startX = startElementX = _round(startElementX);
				self.startY = startElementY = _round(startElementY);
			},

			isTweening = () => self.tween && self.tween.isActive(),

			removePlaceholder = () => {
				if (_placeholderDiv.parentNode && !isTweening() && !self.isDragging) { //_placeholderDiv just props open auto-scrolling containers so they don't collapse as the user drags left/up. We remove it after dragging (and throwing, if necessary) finishes.
					_placeholderDiv.parentNode.removeChild(_placeholderDiv);
				}
			},

			//called when the mouse is pressed (or touch starts)
			onPress = (e, force) => {
				let i;
				if (!enabled || self.isPressed || !e || ((e.type === "mousedown" || e.type === "pointerdown") && !force && _getTime() - clickTime < 30 && _touchEventLookup[self.pointerEvent.type])) { //when we DON'T preventDefault() in order to accommodate touch-scrolling and the user just taps, many browsers also fire a mousedown/mouseup sequence AFTER the touchstart/touchend sequence, thus it'd result in two quick "click" events being dispatched. This line senses that condition and halts it on the subsequent mousedown.
					isPreventingDefault && e && enabled && _preventDefault(e); // in some browsers, we must listen for multiple event types like touchstart, pointerdown, mousedown. The first time this function is called, we record whether or not we _preventDefault() so that on duplicate calls, we can do the same if necessary.
					return;
				}
				interrupted = isTweening();
				dragged = false; // we need to track whether or not it was dragged in this interaction so that if, for example, the user calls .endDrag() to FORCE it to stop and then they keep the mouse pressed down and eventually release, that would normally cause an onClick but we have to skip it in that case if there was dragging that occurred.
				self.pointerEvent = e;
				if (_touchEventLookup[e.type]) { //note: on iOS, BOTH touchmove and mousemove are dispatched, but the mousemove has pageY and pageX of 0 which would mess up the calculations and needlessly hurt performance.
					touchEventTarget = ~e.type.indexOf("touch") ? (e.currentTarget || e.target) : ownerDoc; //pointer-based touches (for Microsoft browsers) don't remain locked to the original target like other browsers, so we must use the document instead. The event type would be "MSPointerDown" or "pointerdown".
					_addListener(touchEventTarget, "touchend", onRelease);
					_addListener(touchEventTarget, "touchmove", onMove); // possible future change if PointerEvents are more standardized: https://developer.mozilla.org/en-US/docs/Web/API/Element/setPointerCapture
					_addListener(touchEventTarget, "touchcancel", onRelease);
					_addListener(ownerDoc, "touchstart", _onMultiTouchDocument);
				} else {
					touchEventTarget = null;
					_addListener(ownerDoc, "mousemove", onMove); //attach these to the document instead of the box itself so that if the user's mouse moves too quickly (and off of the box), things still work.
				}
				touchDragAxis = null;
				if (!_supportsPointer  || !touchEventTarget) {
					_addListener(ownerDoc, "mouseup", onRelease);
					e && e.target && _addListener(e.target, "mouseup", onRelease); //we also have to listen directly on the element because some browsers don't bubble up the event to the _doc on elements with contentEditable="true"
				}
				isClicking = (isClickable.call(self, e.target) && vars.dragClickables === false && !force);
				if (isClicking) {
					_addListener(e.target, "change", onRelease); //in some browsers, when you mousedown on a <select> element, no mouseup gets dispatched! So we listen for a "change" event instead.
					_dispatchEvent(self, "pressInit", "onPressInit");
					_dispatchEvent(self, "press", "onPress");
					_setSelectable(triggers, true); //accommodates things like inputs and elements with contentEditable="true" (otherwise user couldn't drag to select text)
					isPreventingDefault = false;
					return;
				}
				allowNativeTouchScrolling = (!touchEventTarget || allowX === allowY || self.vars.allowNativeTouchScrolling === false || (self.vars.allowContextMenu && e && (e.ctrlKey || e.which > 2))) ? false : allowX ? "y" : "x"; //note: in Chrome, right-clicking (for a context menu) fires onPress and it doesn't have the event.which set properly, so we must look for event.ctrlKey. If the user wants to allow context menus we should of course sense it here and not allow native touch scrolling.
				isPreventingDefault = !allowNativeTouchScrolling && !self.allowEventDefault;
				if (isPreventingDefault) {
					_preventDefault(e);
					_addListener(_win, "touchforcechange", _preventDefault); //works around safari bug: https://gsap.com/forums/topic/21450-draggable-in-iframe-on-mobile-is-buggy/
				}
				if (e.changedTouches) { //touch events store the data slightly differently
					e = touch = e.changedTouches[0];
					touchID = e.identifier;
				} else if (e.pointerId) {
					touchID = e.pointerId; //for some Microsoft browsers
				} else {
					touch = touchID = null;
				}
				_dragCount++;
				_addToRenderQueue(render); //causes the Draggable to render on each "tick" of gsap.ticker (performance optimization - updating values in a mousemove can cause them to happen too frequently, like multiple times between frame redraws which is wasteful, and it also prevents values from updating properly in IE8)
				startPointerY = self.pointerY = e.pageY; //record the starting x and y so that we can calculate the movement from the original in _onMouseMove
				startPointerX = self.pointerX = e.pageX;
				_dispatchEvent(self, "pressInit", "onPressInit");
				if (allowNativeTouchScrolling || self.autoScroll) {
					_recordMaxScrolls(target.parentNode);
				}
				if (target.parentNode && self.autoScroll && !scrollProxy && !rotationMode && target.parentNode._gsMaxScrollX && !_placeholderDiv.parentNode && !target.getBBox) { //add a placeholder div to prevent the parent container from collapsing when the user drags the element left.
					_placeholderDiv.style.width = target.parentNode.scrollWidth + "px";
					target.parentNode.appendChild(_placeholderDiv);
				}
				recordStartPositions();
				self.tween && self.tween.kill();
				self.isThrowing = false;
				gsap.killTweensOf(scrollProxy || target, killProps, true); //in case the user tries to drag it before the last tween is done.
				scrollProxy && gsap.killTweensOf(target, {scrollTo:1}, true); //just in case the original target's scroll position is being tweened somewhere else.
				self.tween = self.lockedAxis = null;
				if (vars.zIndexBoost || (!rotationMode && !scrollProxy && vars.zIndexBoost !== false)) {
					target.style.zIndex = Draggable.zIndex++;
				}
				self.isPressed = true;
				hasDragCallback = !!(vars.onDrag || self._listeners.drag);
				hasMoveCallback = !!(vars.onMove || self._listeners.move);
				if (vars.cursor !== false || vars.activeCursor) {
					i = triggers.length;
					while (--i > -1) {
						gsap.set(triggers[i], {cursor: vars.activeCursor || vars.cursor || (_defaultCursor === "grab" ? "grabbing" : _defaultCursor)});
					}
				}
				_dispatchEvent(self, "press", "onPress");
			},

			//called every time the mouse/touch moves
			onMove = e => {
				let originalEvent = e,
					touches, pointerX, pointerY, i, dx, dy;
				if (!enabled || _isMultiTouching || !self.isPressed || !e) {
					isPreventingDefault && e && enabled && _preventDefault(e); // in some browsers, we must listen for multiple event types like touchmove, pointermove, mousemove. The first time this function is called, we record whether or not we _preventDefault() so that on duplicate calls, we can do the same if necessary.
					return;
				}
				self.pointerEvent = e;
				touches = e.changedTouches;
				if (touches) { //touch events store the data slightly differently
					e = touches[0];
					if (e !== touch && e.identifier !== touchID) { //Usually changedTouches[0] will be what we're looking for, but in case it's not, look through the rest of the array...(and Android browsers don't reuse the event like iOS)
						i = touches.length;
						while (--i > -1 && (e = touches[i]).identifier !== touchID && e.target !== target) {} // Some Android devices dispatch a touchstart AND pointerdown initially, and then only pointermove thus the touchID may not match because it was grabbed from the touchstart event whereas the pointer event is the one that the browser dispatches for move, so if the event target matches this Draggable's target, let it through.
						if (i < 0) {
							return;
						}
					}
				} else if (e.pointerId && touchID && e.pointerId !== touchID) { //for some Microsoft browsers, we must attach the listener to the doc rather than the trigger so that when the finger moves outside the bounds of the trigger, things still work. So if the event we're receiving has a pointerId that doesn't match the touchID, ignore it (for multi-touch)
					return;
				}

				if (touchEventTarget && allowNativeTouchScrolling && !touchDragAxis) { //Android browsers force us to decide on the first "touchmove" event if we should allow the default (scrolling) behavior or preventDefault(). Otherwise, a "touchcancel" will be fired and then no "touchmove" or "touchend" will fire during the scrolling (no good).
					_point1.x = e.pageX - (isFixed ? _getDocScrollLeft(ownerDoc) : 0);
					_point1.y = e.pageY - (isFixed ? _getDocScrollTop(ownerDoc) : 0);
					matrix && matrix.apply(_point1, _point1);
					pointerX = _point1.x;
					pointerY = _point1.y;
					dx = Math.abs(pointerX - startPointerX);
					dy = Math.abs(pointerY - startPointerY);
					if ((dx !== dy && (dx > minimumMovement || dy > minimumMovement)) || (_isAndroid && allowNativeTouchScrolling === touchDragAxis)) {
						touchDragAxis = (dx > dy && allowX) ? "x" : "y";
						if (allowNativeTouchScrolling && touchDragAxis !== allowNativeTouchScrolling) {
							_addListener(_win, "touchforcechange", _preventDefault); // prevents native touch scrolling from taking over if the user started dragging in the other direction in iOS Safari
						}
						if (self.vars.lockAxisOnTouchScroll !== false && allowX && allowY) {
							self.lockedAxis = (touchDragAxis === "x") ? "y" : "x";
							_isFunction(self.vars.onLockAxis) && self.vars.onLockAxis.call(self, originalEvent);
						}
						if (_isAndroid && allowNativeTouchScrolling === touchDragAxis) {
							onRelease(originalEvent);
							return;
						}
					}
				}
				if (!self.allowEventDefault && (!allowNativeTouchScrolling || (touchDragAxis && allowNativeTouchScrolling !== touchDragAxis)) && originalEvent.cancelable !== false) {
					_preventDefault(originalEvent);
					isPreventingDefault = true;
				} else if (isPreventingDefault) {
					isPreventingDefault = false;
				}

				if (self.autoScroll) {
					checkAutoScrollBounds = true;
				}
				setPointerPosition(e.pageX, e.pageY, hasMoveCallback);
			},

			setPointerPosition = (pointerX, pointerY, invokeOnMove) => {
				let dragTolerance = 1 - self.dragResistance,
					edgeTolerance = 1 - self.edgeResistance,
					prevPointerX = self.pointerX,
					prevPointerY = self.pointerY,
					prevStartElementY = startElementY,
					prevX = self.x,
					prevY = self.y,
					prevEndX = self.endX,
					prevEndY = self.endY,
					prevEndRotation = self.endRotation,
					prevDirty = dirty,
					xChange, yChange, x, y, dif, temp;
				self.pointerX = pointerX;
				self.pointerY = pointerY;
				if (isFixed) {
					pointerX -= _getDocScrollLeft(ownerDoc);
					pointerY -= _getDocScrollTop(ownerDoc);
				}
				if (rotationMode) {
					y = Math.atan2(rotationOrigin.y - pointerY, pointerX - rotationOrigin.x) * _RAD2DEG;
					dif = self.y - y;
					if (dif > 180) {
						startElementY -= 360;
						self.y = y;
					} else if (dif < -180) {
						startElementY += 360;
						self.y = y;
					}
					if (self.x !== startElementX || Math.abs(startElementY - y) > minimumMovement) {
						self.y = y;
						x = startElementX + (startElementY - y) * dragTolerance;
					} else {
						x = startElementX;
					}

				} else {
					if (matrix) {
						temp = pointerX * matrix.a + pointerY * matrix.c + matrix.e;
						pointerY = pointerX * matrix.b + pointerY * matrix.d + matrix.f;
						pointerX = temp;
					}
					yChange = (pointerY - startPointerY);
					xChange = (pointerX - startPointerX);
					if (yChange < minimumMovement && yChange > -minimumMovement) {
						yChange = 0;
					}
					if (xChange < minimumMovement && xChange > -minimumMovement) {
						xChange = 0;
					}
					if ((self.lockAxis || self.lockedAxis) && (xChange || yChange)) {
						temp = self.lockedAxis;
						if (!temp) {
							self.lockedAxis = temp = (allowX && Math.abs(xChange) > Math.abs(yChange)) ? "y" : allowY ? "x" : null;
							if (temp && _isFunction(self.vars.onLockAxis)) {
								self.vars.onLockAxis.call(self, self.pointerEvent);
							}
						}
						if (temp === "y") {
							yChange = 0;
						} else if (temp === "x") {
							xChange = 0;
						}
					}
					x = _round(startElementX + xChange * dragTolerance);
					y = _round(startElementY + yChange * dragTolerance);
				}

				if ((snapX || snapY || snapXY) && (self.x !== x || (self.y !== y && !rotationMode))) {
					if (snapXY) {
						_temp1.x = x;
						_temp1.y = y;
						temp = snapXY(_temp1);
						x = _round(temp.x);
						y = _round(temp.y);
					}
					if (snapX) {
						x = _round(snapX(x));
					}
					if (snapY) {
						y = _round(snapY(y));
					}
				}
				if (hasBounds) {
					if (x > maxX) {
						x = maxX + Math.round((x - maxX) * edgeTolerance);
					} else if (x < minX) {
						x = minX + Math.round((x - minX) * edgeTolerance);
					}
					if (!rotationMode) {
						if (y > maxY) {
							y = Math.round(maxY + (y - maxY) * edgeTolerance);
						} else if (y < minY) {
							y = Math.round(minY + (y - minY) * edgeTolerance);
						}
					}
				}
				if (self.x !== x || (self.y !== y && !rotationMode)) {
					if (rotationMode) {
						self.endRotation = self.x = self.endX = x;
						dirty = true;
					} else {
						if (allowY) {
							self.y = self.endY = y;
							dirty = true; //a flag that indicates we need to render the target next time the TweenLite.ticker dispatches a "tick" event (typically on a requestAnimationFrame) - this is a performance optimization (we shouldn't render on every move because sometimes many move events can get dispatched between screen refreshes, and that'd be wasteful to render every time)
						}
						if (allowX) {
							self.x = self.endX = x;
							dirty = true;
						}
					}
					if (!invokeOnMove || _dispatchEvent(self, "move", "onMove") !== false) {
						if (!self.isDragging && self.isPressed) {
							self.isDragging = dragged = true;
							_dispatchEvent(self, "dragstart", "onDragStart");
						}
					} else { //revert because the onMove returned false!
						self.pointerX = prevPointerX;
						self.pointerY = prevPointerY;
						startElementY = prevStartElementY;
						self.x = prevX;
						self.y = prevY;
						self.endX = prevEndX;
						self.endY = prevEndY;
						self.endRotation = prevEndRotation;
						dirty = prevDirty;
					}
				}
			},

			//called when the mouse/touch is released
			onRelease = (e, force) => {
				if (!enabled || !self.isPressed || (e && touchID != null && !force && ((e.pointerId && e.pointerId !== touchID && e.target !== target) || (e.changedTouches && !_hasTouchID(e.changedTouches, touchID))))) {  //for some Microsoft browsers, we must attach the listener to the doc rather than the trigger so that when the finger moves outside the bounds of the trigger, things still work. So if the event we're receiving has a pointerId that doesn't match the touchID, ignore it (for multi-touch)
					isPreventingDefault && e && enabled && _preventDefault(e); // in some browsers, we must listen for multiple event types like touchend, pointerup, mouseup. The first time this function is called, we record whether or not we _preventDefault() so that on duplicate calls, we can do the same if necessary.
					return;
				}
				self.isPressed = false;
				let originalEvent = e,
					wasDragging = self.isDragging,
					isContextMenuRelease = (self.vars.allowContextMenu && e && (e.ctrlKey || e.which > 2)),
					placeholderDelayedCall = gsap.delayedCall(0.001, removePlaceholder),
					touches, i, syntheticEvent, eventTarget, syntheticClick;
				if (touchEventTarget) {
					_removeListener(touchEventTarget, "touchend", onRelease);
					_removeListener(touchEventTarget, "touchmove", onMove);
					_removeListener(touchEventTarget, "touchcancel", onRelease);
					_removeListener(ownerDoc, "touchstart", _onMultiTouchDocument);
				} else {
					_removeListener(ownerDoc, "mousemove", onMove);
				}
				_removeListener(_win, "touchforcechange", _preventDefault);
				if (!_supportsPointer || !touchEventTarget) {
					_removeListener(ownerDoc, "mouseup", onRelease);
					e && e.target && _removeListener(e.target, "mouseup", onRelease);
				}
				dirty = false;
				if (wasDragging) {
					dragEndTime = _lastDragTime = _getTime();
					self.isDragging = false;
				}
				_removeFromRenderQueue(render);
				if (isClicking && !isContextMenuRelease) {
					if (e) {
						_removeListener(e.target, "change", onRelease);
						self.pointerEvent = originalEvent;
					}
					_setSelectable(triggers, false);
					_dispatchEvent(self, "release", "onRelease");
					_dispatchEvent(self, "click", "onClick");
					isClicking = false;
					return;
				}
				i = triggers.length;
				while (--i > -1) {
					_setStyle(triggers[i], "cursor", vars.cursor || (vars.cursor !== false ? _defaultCursor : null));
				}
				_dragCount--;
				if (e) {
					touches = e.changedTouches;
					if (touches) { //touch events store the data slightly differently
						e = touches[0];
						if (e !== touch && e.identifier !== touchID) { //Usually changedTouches[0] will be what we're looking for, but in case it's not, look through the rest of the array...(and Android browsers don't reuse the event like iOS)
							i = touches.length;
							while (--i > -1 && (e = touches[i]).identifier !== touchID && e.target !== target) {}
							if (i < 0 && !force) {
								return;
							}
						}
					}
					self.pointerEvent = originalEvent;
					self.pointerX = e.pageX;
					self.pointerY = e.pageY;
				}
				if (isContextMenuRelease && originalEvent) {
					_preventDefault(originalEvent);
					isPreventingDefault = true;
					_dispatchEvent(self, "release", "onRelease");
				} else if (originalEvent && !wasDragging) {
					isPreventingDefault = false;
					if (interrupted && (vars.snap || vars.bounds)) { //otherwise, if the user clicks on the object while it's animating to a snapped position, and then releases without moving 3 pixels, it will just stay there (it should animate/snap)
						animate(vars.inertia || vars.throwProps);
					}
					_dispatchEvent(self, "release", "onRelease");
					if ((!_isAndroid || originalEvent.type !== "touchmove") && originalEvent.type.indexOf("cancel") === -1) { //to accommodate native scrolling on Android devices, we have to immediately call onRelease() on the first touchmove event, but that shouldn't trigger a "click".
						_dispatchEvent(self, "click", "onClick");
						if (_getTime() - clickTime < 300) {
							_dispatchEvent(self, "doubleclick", "onDoubleClick");
						}
						eventTarget = originalEvent.target || target; //old IE uses srcElement
						clickTime = _getTime();
						syntheticClick = () => { // some browsers (like Firefox) won't trust script-generated clicks, so if the user tries to click on a video to play it, for example, it simply won't work. Since a regular "click" event will most likely be generated anyway (one that has its isTrusted flag set to true), we must slightly delay our script-generated click so that the "real"/trusted one is prioritized. Remember, when there are duplicate events in quick succession, we suppress all but the first one. Some browsers don't even trigger the "real" one at all, so our synthetic one is a safety valve that ensures that no matter what, a click event does get dispatched.
							if (clickTime !== clickDispatch && self.enabled() && !self.isPressed && !originalEvent.defaultPrevented) {
								if (eventTarget.click) { //some browsers (like mobile Safari) don't properly trigger the click event
									eventTarget.click();
								} else if (ownerDoc.createEvent) {
									syntheticEvent = ownerDoc.createEvent("MouseEvents");
									syntheticEvent.initMouseEvent("click", true, true, _win, 1, self.pointerEvent.screenX, self.pointerEvent.screenY, self.pointerX, self.pointerY, false, false, false, false, 0, null);
									eventTarget.dispatchEvent(syntheticEvent);
								}
							}
						};
						if (!_isAndroid && !originalEvent.defaultPrevented) { //iOS Safari requires the synthetic click to happen immediately or else it simply won't work, but Android doesn't play nice.
							gsap.delayedCall(0.05, syntheticClick); //in addition to the iOS bug workaround, there's a Firefox issue with clicking on things like a video to play, so we must fake a click event in a slightly delayed fashion. Previously, we listened for the "click" event with "capture" false which solved the video-click-to-play issue, but it would allow the "click" event to be dispatched twice like if you were using a jQuery.click() because that was handled in the capture phase, thus we had to switch to the capture phase to avoid the double-dispatching, but do the delayed synthetic click. Don't fire it too fast (like 0.00001) because we want to give the native event a chance to fire first as it's "trusted".
						}
					}
				} else {
					animate(vars.inertia || vars.throwProps); //will skip if inertia/throwProps isn't defined or InertiaPlugin isn't loaded.
					if (!self.allowEventDefault && originalEvent && (vars.dragClickables !== false || !isClickable.call(self, originalEvent.target)) && wasDragging && (!allowNativeTouchScrolling || (touchDragAxis && allowNativeTouchScrolling === touchDragAxis)) && originalEvent.cancelable !== false) {
						isPreventingDefault = true;
						_preventDefault(originalEvent);
					} else {
						isPreventingDefault = false;
					}
					_dispatchEvent(self, "release", "onRelease");
				}
				isTweening() && placeholderDelayedCall.duration( self.tween.duration() ); //sync the timing so that the placeholder DIV gets
				wasDragging && _dispatchEvent(self, "dragend", "onDragEnd");
				return true;
			},

			updateScroll = e => {
				if (e && self.isDragging && !scrollProxy) {
					let parent = e.target || target.parentNode,
						deltaX = parent.scrollLeft - parent._gsScrollX,
						deltaY = parent.scrollTop - parent._gsScrollY;
					if (deltaX || deltaY) {
						if (matrix) {
							startPointerX -= deltaX * matrix.a + deltaY * matrix.c;
							startPointerY -= deltaY * matrix.d + deltaX * matrix.b;
						} else {
							startPointerX -= deltaX;
							startPointerY -= deltaY;
						}
						parent._gsScrollX += deltaX;
						parent._gsScrollY += deltaY;
						setPointerPosition(self.pointerX, self.pointerY);
					}
				}
			},

			onClick = e => { //this was a huge pain in the neck to align all the various browsers and their behaviors. Chrome, Firefox, Safari, Opera, Android, and Microsoft Edge all handle events differently! Some will only trigger native behavior (like checkbox toggling) from trusted events. Others don't even support isTrusted, but require 2 events to flow through before triggering native behavior. Edge treats everything as trusted but also mandates that 2 flow through to trigger the correct native behavior.
				let time = _getTime(),
					recentlyClicked = (time - clickTime < 100),
					recentlyDragged = (time - dragEndTime < 50),
					alreadyDispatched = (recentlyClicked && clickDispatch === clickTime),
					defaultPrevented = (self.pointerEvent && self.pointerEvent.defaultPrevented),
					alreadyDispatchedTrusted = (recentlyClicked && trustedClickDispatch === clickTime),
					trusted = e.isTrusted || (e.isTrusted == null && recentlyClicked && alreadyDispatched); //note: Safari doesn't support isTrusted, and it won't properly execute native behavior (like toggling checkboxes) on the first synthetic "click" event - we must wait for the 2nd and treat it as trusted (but stop propagation at that point). Confusing, I know. Don't you love cross-browser compatibility challenges?
				if ((alreadyDispatched || (recentlyDragged && self.vars.suppressClickOnDrag !== false) ) && e.stopImmediatePropagation) {
					e.stopImmediatePropagation();
				}
				if (recentlyClicked && !(self.pointerEvent && self.pointerEvent.defaultPrevented) && (!alreadyDispatched || (trusted && !alreadyDispatchedTrusted))) { //let the first click pass through unhindered. Let the next one only if it's trusted, then no more (stop quick-succession ones)
					if (trusted && alreadyDispatched) {
						trustedClickDispatch = clickTime;
					}
					clickDispatch = clickTime;
					return;
				}
				if (self.isPressed || recentlyDragged || recentlyClicked) {
					if (!trusted || !e.detail || !recentlyClicked || defaultPrevented) {
						_preventDefault(e);
					}
				}
				if (!recentlyClicked && !recentlyDragged && !dragged) { // for script-triggered event dispatches, like element.click()
					e && e.target && (self.pointerEvent = e);
					_dispatchEvent(self, "click", "onClick");
				}
			},

			localizePoint = p => matrix ? {x:p.x * matrix.a + p.y * matrix.c + matrix.e, y:p.x * matrix.b + p.y * matrix.d + matrix.f} : {x:p.x, y:p.y};

		old = Draggable.get(target);
		old && old.kill(); // avoids duplicates (an element can only be controlled by one Draggable)

		//give the user access to start/stop dragging...
		this.startDrag = (event, align) => {
			let r1, r2, p1, p2;
			onPress(event || self.pointerEvent, true);
			//if the pointer isn't on top of the element, adjust things accordingly
			if (align && !self.hitTest(event || self.pointerEvent)) {
				r1 = _parseRect(event || self.pointerEvent);
				r2 = _parseRect(target);
				p1 = localizePoint({x:r1.left + r1.width / 2, y:r1.top + r1.height / 2});
				p2 = localizePoint({x:r2.left + r2.width / 2, y:r2.top + r2.height / 2});
				startPointerX -= p1.x - p2.x;
				startPointerY -= p1.y - p2.y;
			}
			if (!self.isDragging) {
				self.isDragging = dragged = true;
				_dispatchEvent(self, "dragstart", "onDragStart");
			}
		};
		this.drag = onMove;
		this.endDrag = e =>	onRelease(e || self.pointerEvent, true);
		this.timeSinceDrag = () => self.isDragging ? 0 : (_getTime() - dragEndTime) / 1000;
		this.timeSinceClick = () => (_getTime() - clickTime) / 1000;
		this.hitTest = (target, threshold) => Draggable.hitTest(self.target, target, threshold);

		this.getDirection = (from, diagonalThreshold) => { //from can be "start" (default), "velocity", or an element
			let mode = (from === "velocity" && InertiaPlugin) ? from : (_isObject(from) && !rotationMode) ? "element" : "start",
				xChange, yChange, ratio, direction, r1, r2;
			if (mode === "element") {
				r1 = _parseRect(self.target);
				r2 = _parseRect(from);
			}
			xChange = (mode === "start") ? self.x - startElementX : (mode === "velocity") ? InertiaPlugin.getVelocity(target, xProp) : (r1.left + r1.width / 2) - (r2.left + r2.width / 2);
			if (rotationMode) {
				return xChange < 0 ? "counter-clockwise" : "clockwise";
			} else {
				diagonalThreshold = diagonalThreshold || 2;
				yChange = (mode === "start") ? self.y - startElementY : (mode === "velocity") ? InertiaPlugin.getVelocity(target, yProp) : (r1.top + r1.height / 2) - (r2.top + r2.height / 2);
				ratio = Math.abs(xChange / yChange);
				direction = (ratio < 1 / diagonalThreshold) ? "" : (xChange < 0) ? "left" : "right";
				if (ratio < diagonalThreshold) {
					if (direction !== "") {
						direction += "-";
					}
					direction += (yChange < 0) ? "up" : "down";
				}
			}
			return direction;
		};

		this.applyBounds = (newBounds, sticky) => {
			let x, y, forceZeroVelocity, e, parent, isRoot;
			if (newBounds && vars.bounds !== newBounds) {
				vars.bounds = newBounds;
				return self.update(true, sticky);
			}
			syncXY(true);
			calculateBounds();
			if (hasBounds && !isTweening()) {
				x = self.x;
				y = self.y;
				if (x > maxX) {
					x = maxX;
				} else if (x < minX) {
					x = minX;
				}
				if (y > maxY) {
					y = maxY;
				} else if (y < minY) {
					y = minY;
				}
				if (self.x !== x || self.y !== y) {
					forceZeroVelocity = true;
					self.x = self.endX = x;
					if (rotationMode) {
						self.endRotation = x;
					} else {
						self.y = self.endY = y;
					}
					dirty = true;
					render(true);
					if (self.autoScroll && !self.isDragging) {
						_recordMaxScrolls(target.parentNode);
						e = target;
						_windowProxy.scrollTop = ((_win.pageYOffset != null) ? _win.pageYOffset : (ownerDoc.documentElement.scrollTop != null) ? ownerDoc.documentElement.scrollTop : ownerDoc.body.scrollTop);
						_windowProxy.scrollLeft = ((_win.pageXOffset != null) ? _win.pageXOffset : (ownerDoc.documentElement.scrollLeft != null) ? ownerDoc.documentElement.scrollLeft : ownerDoc.body.scrollLeft);
						while (e && !isRoot) { //walk up the chain and sense wherever the scrollTop/scrollLeft exceeds the maximum.
							isRoot = _isRoot(e.parentNode);
							parent = isRoot ? _windowProxy : e.parentNode;
							if (allowY && parent.scrollTop > parent._gsMaxScrollY) {
								parent.scrollTop = parent._gsMaxScrollY;
							}
							if (allowX && parent.scrollLeft > parent._gsMaxScrollX) {
								parent.scrollLeft = parent._gsMaxScrollX;
							}
							e = parent;
						}
					}
				}
				if (self.isThrowing && (forceZeroVelocity || self.endX > maxX || self.endX < minX || self.endY > maxY || self.endY < minY)) {
					animate(vars.inertia || vars.throwProps, forceZeroVelocity);
				}
			}
			return self;
		};

		this.update = (applyBounds, sticky, ignoreExternalChanges) => {
			if (sticky && self.isPressed) { // in case the element was repositioned in the document flow, thus its x/y may be identical but its position is actually quite different.
				let m = getGlobalMatrix(target),
					p = innerMatrix.apply({x: self.x - startElementX, y: self.y - startElementY}),
					m2 = getGlobalMatrix(target.parentNode, true);
				m2.apply({x: m.e - p.x, y: m.f - p.y}, p);
				self.x -= p.x - m2.e;
				self.y -= p.y - m2.f;
				render(true);
				recordStartPositions();
			}
			let { x, y } = self;
			updateMatrix(!sticky);
			if (applyBounds) {
				self.applyBounds();
			} else {
				dirty && ignoreExternalChanges && render(true);
				syncXY(true);
			}
			if (sticky) {
				setPointerPosition(self.pointerX, self.pointerY);
				dirty && render(true);
			}
			if (self.isPressed && !sticky && ((allowX && Math.abs(x - self.x) > 0.01) || (allowY && (Math.abs(y - self.y) > 0.01 && !rotationMode)))) {
				recordStartPositions();
			}
			if (self.autoScroll) {
				_recordMaxScrolls(target.parentNode, self.isDragging);
				checkAutoScrollBounds = self.isDragging;
				render(true);
				//in case reparenting occurred.
				_removeScrollListener(target, updateScroll);
				_addScrollListener(target, updateScroll);
			}
			return self;
		};

		this.enable = type => {
			let setVars = {lazy: true},
				id, i, trigger;
			if (vars.cursor !== false) {
				setVars.cursor = vars.cursor || _defaultCursor;
			}
			if (gsap.utils.checkPrefix("touchCallout")) {
				setVars.touchCallout = "none";
			}
			if (type !== "soft") {
				_setTouchActionForAllDescendants(triggers, (allowX === allowY) ? "none" : (vars.allowNativeTouchScrolling && (target.scrollHeight === target.clientHeight) === (target.scrollWidth === target.clientHeight)) || vars.allowEventDefault ? "manipulation" : allowX ? "pan-y" : "pan-x"); // Some browsers like Internet Explorer will fire a pointercancel event when the user attempts to drag when touchAction is "manipulate" because it's perceived as a pan. If the element has scrollable content in only one direction, we should use pan-x or pan-y accordingly so that the pointercancel doesn't prevent dragging.
				i = triggers.length;
				while (--i > -1) {
					trigger = triggers[i];
					_supportsPointer || _addListener(trigger, "mousedown", onPress);
					_addListener(trigger, "touchstart", onPress);
					_addListener(trigger, "click", onClick, true); // note: used to pass true for capture but it prevented click-to-play-video functionality in Firefox.
					gsap.set(trigger, setVars);
					if (trigger.getBBox && trigger.ownerSVGElement && allowX !== allowY) { // a bug in chrome doesn't respect touch-action on SVG elements - it only works if we set it on the parent SVG.
						gsap.set(trigger.ownerSVGElement, {touchAction: vars.allowNativeTouchScrolling || vars.allowEventDefault ? "manipulation" : allowX ? "pan-y" : "pan-x"});
					}
					vars.allowContextMenu || _addListener(trigger, "contextmenu", onContextMenu);
				}
				_setSelectable(triggers, false);
			}
			_addScrollListener(target, updateScroll);
			enabled = true;
			if (InertiaPlugin && type !== "soft") {
				InertiaPlugin.track(scrollProxy || target, (xyMode ? "x,y" : rotationMode ? "rotation" : "top,left"));
			}
			target._gsDragID = id = "d" + (_lookupCount++);
			_lookup[id] = self;
			if (scrollProxy) {
				scrollProxy.enable();
				scrollProxy.element._gsDragID = id;
			}
			(vars.bounds || rotationMode) && recordStartPositions();
			vars.bounds && self.applyBounds();
			return self;
		};

		this.disable = type => {
			let dragging = self.isDragging,
				i = triggers.length,
				trigger;
			while (--i > -1) {
				_setStyle(triggers[i], "cursor", null);
			}
			if (type !== "soft") {
				_setTouchActionForAllDescendants(triggers, null);
				i = triggers.length;
				while (--i > -1) {
					trigger = triggers[i];
					_setStyle(trigger, "touchCallout", null);
					_removeListener(trigger, "mousedown", onPress);
					_removeListener(trigger, "touchstart", onPress);
					_removeListener(trigger, "click", onClick, true);
					_removeListener(trigger, "contextmenu", onContextMenu);
				}
				_setSelectable(triggers, true);
				if (touchEventTarget) {
					_removeListener(touchEventTarget, "touchcancel", onRelease);
					_removeListener(touchEventTarget, "touchend", onRelease);
					_removeListener(touchEventTarget, "touchmove", onMove);
				}
				_removeListener(ownerDoc, "mouseup", onRelease);
				_removeListener(ownerDoc, "mousemove", onMove);
			}
			_removeScrollListener(target, updateScroll);
			enabled = false;
			if (InertiaPlugin && type !== "soft") {
				InertiaPlugin.untrack(scrollProxy || target, (xyMode ? "x,y" : rotationMode ? "rotation" : "top,left"));
				self.tween && self.tween.kill();
			}
			scrollProxy && scrollProxy.disable();
			_removeFromRenderQueue(render);
			self.isDragging = self.isPressed = isClicking = false;
			dragging && _dispatchEvent(self, "dragend", "onDragEnd");
			return self;
		};

		this.enabled = function(value, type) {
			return arguments.length ? (value ? self.enable(type) : self.disable(type)) : enabled;
		};

		this.kill = function() {
			self.isThrowing = false;
			self.tween && self.tween.kill();
			self.disable();
			gsap.set(triggers, {clearProps:"userSelect"});
			delete _lookup[target._gsDragID];
			return self;
		};

		this.revert = function() {
			this.kill();
			this.styles && this.styles.revert();
		};

		if (~type.indexOf("scroll")) {
			scrollProxy = this.scrollProxy = new ScrollProxy(target, _extend({onKill:function() { //ScrollProxy's onKill() gets called if/when the ScrollProxy senses that the user interacted with the scroll position manually (like using the scrollbar). IE9 doesn't fire the "mouseup" properly when users drag the scrollbar of an element, so this works around that issue.
					self.isPressed && onRelease(null);
			}}, vars));
			//a bug in many Android devices' stock browser causes scrollTop to get forced back to 0 after it is altered via JS, so we set overflow to "hidden" on mobile/touch devices (they hide the scroll bar anyway). That works around the bug. (This bug is discussed at https://code.google.com/p/android/issues/detail?id=19625)
			target.style.overflowY = (allowY && !_isTouchDevice) ? "auto" : "hidden";
			target.style.overflowX = (allowX && !_isTouchDevice) ? "auto" : "hidden";
			target = scrollProxy.content;
		}

		if (rotationMode) {
			killProps.rotation = 1;
		} else {
			if (allowX) {
				killProps[xProp] = 1;
			}
			if (allowY) {
				killProps[yProp] = 1;
			}
		}

		gsCache.force3D = ("force3D" in vars) ? vars.force3D : true; //otherwise, normal dragging would be in 2D and then as soon as it's released and there's an inertia tween, it'd jump to 3D which can create an initial jump due to the work the browser must to do layerize it.

		_context(this);
		this.enable();
	}




	static register(core) {
		gsap = core;
		_initCore();
	}

	static create(targets, vars) {
		_coreInitted || _initCore(true);
		return _toArray(targets).map(target => new Draggable(target, vars));
	}

	static get(target) {
		return _lookup[(_toArray(target)[0] || {})._gsDragID];
	}

	static timeSinceDrag() {
		return (_getTime() - _lastDragTime) / 1000;
	}

	static hitTest(obj1, obj2, threshold) {
		if (obj1 === obj2) {
			return false;
		}
		let r1 = _parseRect(obj1),
			r2 = _parseRect(obj2),
			{ top, left, right, bottom, width, height } = r1,
			isOutside = (r2.left > right || r2.right < left || r2.top > bottom || r2.bottom < top),
			overlap, area, isRatio;
		if (isOutside || !threshold) {
			return !isOutside;
		}
		isRatio = ((threshold + "").indexOf("%") !== -1);
		threshold = parseFloat(threshold) || 0;
		overlap = {left: Math.max(left, r2.left), top: Math.max(top, r2.top)};
		overlap.width = Math.min(right, r2.right) - overlap.left;
		overlap.height = Math.min(bottom, r2.bottom) - overlap.top;
		if (overlap.width < 0 || overlap.height < 0) {
			return false;
		}
		if (isRatio) {
			threshold *= 0.01;
			area = overlap.width * overlap.height;
			return (area >= width * height * threshold || area >= r2.width * r2.height * threshold);
		}
		return (overlap.width > threshold && overlap.height > threshold);
	}

}

_setDefaults(Draggable.prototype, {pointerX:0, pointerY: 0, startX: 0, startY: 0, deltaX: 0, deltaY: 0, isDragging: false, isPressed: false});

Draggable.zIndex = 1000;
Draggable.version = "3.12.5";

_getGSAP() && gsap.registerPlugin(Draggable);

export { Draggable as default };